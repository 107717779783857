// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
let firebaseConfig = {}
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APP_KEY_PRD,
    authDomain: 'trendlabs-app-store-stg.firebaseapp.com',
    projectId: 'trendlabs-app-store-stg',
    storageBucket: 'trendlabs-app-store-stg.appspot.com',
    messagingSenderId: '672336620106',
    appId: '1:672336620106:web:3419129d5397c70bb994b3',
    measurementId: 'G-QCFSZ2F22T',
  }
} else {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APP_KEY_STG,
    authDomain: 'trendlabs-app-store-prd.firebaseapp.com',
    projectId: 'trendlabs-app-store-prd',
    storageBucket: 'trendlabs-app-store-prd.appspot.com',
    messagingSenderId: '962850553082',
    appId: '1:962850553082:web:610b10aef822cd6fde9d43',
    measurementId: 'G-YEVZMTRKNF',
  }
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
