const styles = {
  headerContainer: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoSize: {
    height: 40,
  },
  appListContainer: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
}

export default styles
