import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import { useEffect } from 'react'

export const FirstVisitModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    onOpen()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>FAQs can be viewed at the bottom of the page.</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <a href="#faqs">
              <Button variant="ghost" onClick={onClose}>
                Go to FAQs
              </Button>
            </a>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
