import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import { useEffect } from 'react'

export const SessionExpiredModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    onOpen()
    // eslint-disable-next-line
  }, [])

  const reloadPage = () => {
    onClose()
    window.location.reload()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Session Expired</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Your session has expired. Click 'Try Again' to reload portal.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={reloadPage}>
              Try Again
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
