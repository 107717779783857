if (process.env.REACT_APP_ENV === 'production') {
  console.log = () => {}
}

export const getCDN = () => {
  if (process.env.REACT_APP_ENV !== 'production') {
    console.log('cdn-stg-env')
    return process.env.REACT_APP_CDN_STG
  }
  console.log('cdn-prd-env')
  return process.env.REACT_APP_CDN_PRD
}

export const getAPI = () => {
  if (process.env.REACT_APP_ENV !== 'production') {
    console.log('cdn-stg-env')
    return process.env.REACT_APP_API_STG
  }
  console.log('cdn-prd-env')
  return process.env.REACT_APP_API_PRD
}
