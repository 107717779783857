import { getCDN } from './utils/getEnvironment'

export const apps = [
  {
    imageUrl: `${getCDN()}shuttlepassenger/icon.png`,
    appDescription: 'Shuttle service app for Trenders',
    imageAlt: 'shuttle-app',
    title: 'Shuttle App',
    isNew: true,
    app: {
      foldername: 'shuttlepassenger',
      android: 'shuttlepassenger_v' + process.env.REACT_APP_SHUTTLEPASSENGER,
    },
  },
  {
    imageUrl: `${getCDN()}trendeats/icon.png`,
    appDescription: 'Food service app for Trenders',
    imageAlt: 'trendeats-app',
    title: 'TrendEats App',
    isNew: false,
    app: {
      foldername: 'trendeats',
      android: 'TrendEats v' + process.env.REACT_APP_TRENDEATS,
    },
  },
  {
    imageUrl: `${getCDN()}etdg/icon.png`,
    appDescription: 'Engineered To Do Good Community app',
    imageAlt: 'etdg-app',
    title: 'ETDG Community',
    app: {
      foldername: 'etdg',
      android: 'ETDG Community v' + process.env.REACT_APP_ETDG,
    },
  },
  // {
  //   imageUrl: `${getCDN()}techcybertactics/icon.png`,
  //   appDescription: 'Techcamp 2023 event game',
  //   imageAlt: 'techcybertactics-app',
  //   title: 'Tech Cyber Tactics',
  //   isNew: false,
  //   isDisabled: true,
  //   app: {
  //     foldername: 'techcybertactics',
  //     android: 'tech-cyber-tactics-v' + process.env.REACT_APP_TECHCAMPTACTICS,
  //   },
  // },
  // {
  //   imageUrl: `${getCDN()}trendbingo/icon-vday2023.png`,
  //   appDescription: `Valentine's Day Celebration 2023`,
  //   imageAlt: 'trend-bingo',
  //   title: 'Trend Bingo',
  //   isDisabled: true,
  //   isNew: false,
  //   app: {
  //     foldername: 'trendbingo',
  //     android: 'TrendBingov' + process.env.REACT_APP_BINGO,
  //   },
  // },
]
