import React, { useEffect } from 'react'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { loginRequest } from './authConfig'

export const Login = () => {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e)
    })
  }

  useEffect(() => {
    isAuthenticated ? console.log('Login') : handleLogin()
    // eslint-disable-next-line
  }, [])

  return <></>
}
