export const msalConfigPRD = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID_PRD,
    authority:
      'https://login.microsoftonline.com/' + process.env.REACT_APP_TENANT_ID,
    redirectUri: 'https://trendlabs-appstore.trendmicro.com.ph/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if y ou are having issues on IE11 or Edge
  },
}

export const msalConfigSTG = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID_STG,
    authority:
      'https://login.microsoftonline.com/' + process.env.REACT_APP_TENANT_ID,
    redirectUri: 'https://trendlabs-appstore-stg.trendmicro.com.ph/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if y ou are having issues on IE11 or Edge
  },
}

export const msalConfigLocal = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID_STG,
    authority:
      'https://login.microsoftonline.com/' + process.env.REACT_APP_TENANT_ID,
    redirectUri: 'http://localhost:3000',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if y ou are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
