import axios from 'axios'
import { getAPI } from './getEnvironment'

export const fetchSASToken = async (idToken, setSessionExpired) => {
  try {
    const response = await axios.post(
      getAPI() + 'generate-sas',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + idToken,
        },
      }
    )
    return response
  } catch (e) {
    console.log('Generating SAS token error:', e)
    setSessionExpired(true)
  }
}
