import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { PublicClientApplication } from '@azure/msal-browser'
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'

import { msalConfigPRD, msalConfigLocal, msalConfigSTG } from './authConfig'
import { Login } from './Login'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

if (process.env.REACT_APP_ENV === 'production') {
  console.log = () => {}
}

const msalConfig = () => {
  if (process.env.REACT_APP_ENV === 'staging') {
    console.log('msal-stg')
    return msalConfigSTG
  } else if (process.env.REACT_APP_ENV === 'develop') {
    console.log('msal-local')
    return msalConfigLocal
  }
  console.log('msal-prd')
  return msalConfigPRD
}

const msalInstance = new PublicClientApplication(msalConfig())

console.log('ENV: ', process.env.REACT_APP_ENV)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <MsalProvider instance={msalInstance}>
    <AuthenticatedTemplate>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Login />
    </UnauthenticatedTemplate>
  </MsalProvider>
)
