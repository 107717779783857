import { Box, Text, Image } from '@chakra-ui/react'
import React from 'react'
import { Accordion } from 'react-bootstrap'

export const FaqScreenAccordion = () => {
  const faqs = [
    {
      question: 'How to download and install apps?',
      answer: () => {
        return (
          <Box>
            <Text mb={5}>
              <b>Step 1:</b> Choose an app to download from the list above.
            </Text>
            <Text mb={5}>
              <b>Step 2:</b> Click the button "Download App".
            </Text>
            <Text mb={5}>
              <b>Step 3:</b> Wait until the download is finished.
            </Text>
            <Text mb={5}>
              <b>Step 4:</b> Open the installer.
            </Text>
            <Text>
              <b>Step 5:</b> When prompted to install, press "Install".
            </Text>
          </Box>
        )
      },
    },
    {
      question: 'iOS: Untrusted Enterprise Developer.',
      answer: () => {
        return (
          <Box maxW={500}>
            <Text mb={5}>
              <b>Step 1:</b> Go to "Settings" {'>'} "General" {'>'} "VPN {'&'}{' '}
              Device Management"
            </Text>
            <Text>
              <b>Step 2:</b> Press "Trend Micro Incorporated" under Enterprise
              App
            </Text>
            <Box display={'flex'} justifyContent={'center'}>
              <Image mb={5} src="assets/stepA.png" style={{ width: 300 }} />
            </Box>
            <Text>
              <b>Step 3:</b> Press "Trust".
            </Text>
            <Box display={'flex'} justifyContent={'center'}>
              <Image src="assets/stepB.png" style={{ width: 300 }} />
            </Box>
          </Box>
        )
      },
    },
    {
      question: 'I have additional questions. Who do I contact?',
      answer: () => {
        return (
          <Box>
            You can contact us through this email:{' '}
            <Text
              onClick={() => {
                window.open('mailto:alloftrendphismobileteam@dl.trendmicro.com')
              }}
            >
              <b>alloftrendphismobileteam@dl.trendmicro.com</b>
            </Text>
          </Box>
        )
      },
    },
  ]

  return (
    <Box marginTop={50} marginBottom={50}>
      <Text textAlign={'center'} fontSize={'4xl'} fontWeight={'bold'}>
        FAQs
      </Text>
      <Accordion>
        {faqs.map((item, index) => {
          return (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>
                {index + 1}) {item.question}
              </Accordion.Header>
              <Accordion.Body>{item.answer()}</Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </Box>
  )
}
