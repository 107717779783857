import React, { useEffect, useState } from 'react'
import { Box, Text, Image } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'

import { download } from '../utils/download'
import { AppBoxItem } from '../components/appBoxItem'
import { FaqScreenAccordion } from '../components/faq'
import { FirstVisitModal } from '../components/firstVisitModal'
import { SessionExpiredModal } from '../components/sessionExpiredModal'
import { apps } from '../appList'
import { getAPI } from '../utils/getEnvironment'
import styles from '../appStyles'
import '../App.css'

const Home = () => {
  const [isFirstVisit, setIsFirstVisit] = useState(false)
  const [sessionExpired, setSessionExpired] = useState(false)
  const { instance, accounts } = useMsal()
  const [idToken, setIdToken] = useState(null)
  const [sasToken, setSasToken] = useState('')
  const [isStaging, setIsStaging] = useState(false)

  useEffect(() => {
    const checkFirstVisit = localStorage.getItem('isFirstVisit')

    if (checkFirstVisit) {
      setIsFirstVisit(false)
    } else {
      setIsFirstVisit(true)
    }

    setIsStaging(() => {
      if (process.env.REACT_APP_ENV !== 'production') {
        return true
      }
    })

    localStorage.setItem('isFirstVisit', JSON.stringify(true))

    const fetchSASToken = async () => {
      try {
        const idTokenResponse = await instance.acquireTokenSilent({
          account: accounts[0],
          scopes: ['User.Read'],
        })

        setIdToken(idTokenResponse.idToken)
        const sasTokenresponse = await axios.post(
          getAPI() + 'generate-sas',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + idTokenResponse.idToken,
            },
          }
        )
        setSasToken(sasTokenresponse.data.sasToken)
      } catch (e) {
        console.log('Generating SAS token for image error:', e)
      }
    }

    fetchSASToken()
  }, [])

  return (
    <Box>
      <Box
        w="100%"
        h="200"
        bgGradient="linear(to-b, #fff 25%,#b9d3e9 40%, #2059b4 90%)"
      >
        <Box style={styles.headerContainer}>
          <Image src="assets/logo.png" alt="icon" style={styles.logoSize} />

          <Text
            color={'#000'}
            style={{ marginLeft: 10 }}
            fontSize="2xl"
            as="i"
            fontWeight={'500'}
          >
            TrendLabs
          </Text>
          <Text
            color={'#000'}
            style={{ marginLeft: 5 }}
            fontSize="2xl"
            fontWeight={'200'}
          >
            app store {isStaging ? ' [STG]' : null}
          </Text>
        </Box>
        <Box padding={5} paddingLeft={8}>
          <Box w="100%">
            <Text
              color={'#fff'}
              fontSize="xl"
              lineHeight={1}
              textAlign="center"
            >
              All-in-one platform for the Trender's needs.
            </Text>
          </Box>
          <Box w="100%" marginTop={5}>
            <Text
              color={'#00FFFF'}
              fontSize="xl"
              lineHeight={1}
              fontWeight="700"
              textAlign="center"
            >
              State of the art apps engineered for Trenders.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box style={styles.appListContainer}>
        <Alert variant={'secondary'} style={styles.alert}>
          <WarningIcon marginRight={5} />
          <Text fontSize={'sm'}>
            These apps will be downloaded as independent native app.
          </Text>
        </Alert>
        <Box
          justifyContent={'space-between'}
          alignItems={'center'}
          display={'flex'}
        >
          <Text fontWeight="700" fontSize={'xl'}>
            List of Apps
          </Text>
          <a href="#faqs">
            <Text fontSize={'xl'} textDecorationLine={'underline'}>
              Go to FAQs
            </Text>
          </a>
        </Box>
        <Box className="App row" style={{ justifyContent: 'center' }}>
          {apps.map((item) => {
            return (
              <AppBoxItem
                key={item.title}
                item={item}
                download={download}
                sasToken={sasToken}
                idToken={idToken}
                setSessionExpired={setSessionExpired}
              />
            )
          })}
        </Box>

        <section id="faqs" />
        <FaqScreenAccordion />
      </Box>
      {isFirstVisit ? <FirstVisitModal /> : null}
      {sessionExpired ? <SessionExpiredModal /> : null}
    </Box>
  )
}

export default Home
