import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { getAPI } from './getEnvironment'

export const checkHash = async (app, userHash, idToken) => {
  const decoded = jwt_decode(idToken)

  const response = await axios.post(
    getAPI() + 'check-hash',
    {
      app,
      userHash,
      email: decoded.preferred_username,
    },
    {
      headers: {
        Authorization: 'Bearer ' + idToken,
      },
    }
  )

  return response.data.isMatch
}
