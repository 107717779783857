import React from 'react'
import { Box, Text, Flex } from '@chakra-ui/react'
import { List, ListItem, ListIcon } from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'

function PrivacyPolicy() {
  return (
    <Box>
      <Flex justify={'center'} bgColor={'green'} pt={20} pb={28} mb={10}>
        <Text
          fontSize={'4xl'}
          as={'b'}
          color={'#fff'}
          fontFamily={'sans-serif'}
        >
          Privacy Policy
        </Text>
      </Flex>
      <Box p={8}>
        <Box>
          <Text
            fontSize={'xl'}
            paddingBottom={10}
            lineHeight={9}
            fontFamily={'sans-serif'}
            color={'#434343'}
          >
            In compliance with the Data Privacy Act of 2012, (DPA) and its
            Implementing Rules and Regulations (IRR) effective on September 8,
            2016, I agree and authorize Trend Micro to:
          </Text>
        </Box>
        <Box>
          <List spacing={3}>
            <ListItem color={'#696969'}>
              <ListIcon as={MdCheckCircle} color="#e6004a" w={6} h={5} />
              Collect my information for registration and verification for the
              application's use.
            </ListItem>
            <ListItem color={'#696969'}>
              <ListIcon as={MdCheckCircle} color="#e6004a" w={6} h={5} />
              Retain my information for a period of five years from the date of
              registration or until I submit to Trend Micro a written
              cancellation of this consent, whichever is earlier. I agree that
              my information will be deleted/destroyed after this period.
            </ListItem>
            <ListItem color={'#696969'}>
              <ListIcon as={MdCheckCircle} color="#e6004a" w={6} h={5} />
              Inform me of technical updates and future company events using the
              personal information I shared with Trend Micro.
            </ListItem>

            <ListItem color={'#696969'}>
              <ListIcon as={MdCheckCircle} color="#e6004a" w={6} h={5} />I also
              acknowledge and warrant that I have acquired the consent from all
              parties relevant to this consent and hold free and harmless and
              indemnify Trend Micro from any complaint, suit, or damages which
              any party may file or claim in relation to my consent.
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}

export default PrivacyPolicy
