import { BlobServiceClient } from '@azure/storage-blob'
import { Buffer } from 'buffer'
import { logEvent } from 'firebase/analytics'

import { analytics } from './firebaseConfig'
import { getCDN } from './getEnvironment'
import { fetchSASToken } from './fetchSASToken'
import { checkHash } from './checkHash'

export const download = async (
  appTitle,
  app,
  os,
  idToken,
  setSessionExpired
) => {
  const container = app.foldername
  const responseToken = await fetchSASToken(idToken, setSessionExpired)

  console.log('appTitle: ', container)

  if (os === 'iOS') {
    await logEvent(analytics, `${container}-iOS`, {
      platform: 'iOS',
    })

    const uri = encodeURIComponent(
      `${getCDN()}${container}/manifest.plist?` + responseToken.data.sasToken
    )

    window.location.href =
      'itms-services://?action=download-manifest&url=' + uri
  } else {
    try {
      const connectionString = () => {
        if (process.env.REACT_APP_ENV !== 'production') {
          return `${process.env.REACT_APP_CONNECTION_STRING_STG}${responseToken.data.sasToken}`
        } else {
          return `${process.env.REACT_APP_CONNECTION_STRING_PRD}${responseToken.data.sasToken}`
        }
      }

      const containerName = 'ph-mobile-apps/' + app.foldername
      const blobName =
        process.env.REACT_APP_ENV !== 'production'
          ? app.android + '_stg.apk'
          : app.android + '.apk'

      const blobServiceClient = await BlobServiceClient.fromConnectionString(
        connectionString()
      )

      const containerClient =
        blobServiceClient.getContainerClient(containerName)
      const blobClient = containerClient.getBlobClient(blobName)

      const blobProperties = await blobClient.getProperties()
      const contentMD5 = blobProperties.contentMD5
      const md5Buffer = Buffer.from(contentMD5)
      const md5String = md5Buffer.toString('hex')

      if (await checkHash(app.foldername, md5String, idToken)) {
        console.log('Downloading app.')
        await logEvent(analytics, `${container}-Android`, {
          platform: 'Android',
        })
        window.location.href = await (
          await blobClient.download()
        )._response.request.url
      } else {
        console.log('Hash does not match.')
      }
    } catch (e) {
      console.log('Fetching blob error:', e)
    }
  }
}
