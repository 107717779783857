import React, { useState } from 'react'
import { Box, Image, Button, Badge, Text, Progress } from '@chakra-ui/react'

export const AppBoxItem = (props) => {
  // eslint-disable-next-line
  const { item, download, sasToken, idToken, setSessionExpired } = props
  const [isLoading, setIsLoading] = useState(false)

  const os = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }

    return 'unknown'
  }

  return (
    <Box maxW="160" borderRadius="lg" overflow="hidden" margin={2} padding={2}>
      <Box display={'flex'} justifyContent={'center'}>
        <Image
          src={item.imageUrl + '?' + sasToken}
          alt={item.imageAlt}
          maxW={100}
        />
      </Box>
      <Box position={'absolute'} marginTop={-6} marginLeft={20}>
        {item.isNew ? (
          <Badge ml="1" colorScheme="green">
            New
          </Badge>
        ) : (
          <></>
        )}
      </Box>
      <Box p="1">
        <Box
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          noOfLines={1}
          fontSize="sm"
        >
          {item.title}
        </Box>
        <Box>
          <Text fontSize="xs">{item.appDescription}</Text>
        </Box>
      </Box>
      {isLoading ? (
        <Progress
          size="sm"
          isIndeterminate
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        />
      ) : (
        <Button
          colorScheme="teal"
          variant="outline"
          style={{ padding: 15, height: 30 }}
          onClick={async () => {
            try {
              // await download()
              setIsLoading(true)
              await download(
                item.title,
                item.app,
                os(),
                idToken,
                setSessionExpired
              )
              setIsLoading(false)
            } catch (e) {
              console.log('Download error:', e)
              setIsLoading(false)
            }
          }}
          isDisabled={item.isDisabled || (item.disableIOS && os() === 'iOS')}
        >
          {item.disableIOS && os() === 'iOS' ? (
            <Text fontSize={'sm'}>iOS not Available</Text>
          ) : (
            <Text fontSize={'sm'}>Download App</Text>
          )}
        </Button>
      )}
    </Box>
  )
}
